<template>
  <div>
    <div class="top">
      <div class="left" @click="index"></div>
      <div class="right">
        <div class="picture" v-if="userinfo == null ? false : true">
          <img :src="userinfo.avatar" alt="" />
        </div>
        <div class="picture" v-else @click.stop="gologin">Log in</div>
        <div class="more" @click.stop="show = true">
          <img src="~@/assets/img/more.png" alt="" />
        </div>
      </div>
    </div>
    <div class="top_block"></div>
    <van-popup
      v-model:show="show"
      position="right"
      :style="{ width: '80%', height: '100%' }"
    >
      <div class="top_menu">
        <div class="left" @click="index">
          <div class="logo">
            <img src="~@/assets/img/logo_top.png" alt="" />
          </div>
        </div>
        <div class="right" @click="more">
          <div class="more"><img src="~@/assets/img/quxiao.png" alt="" /></div>
        </div>
      </div>
      <div
        v-if="userinfo == null ? false : true"
        style="
          background-color: rgba(255, 255, 255, 0.8);
          width: 100%;
          height: 0.5rem;
        "
      >
        <div class="nav">
          <div class="left">
            <div class="picture"><img :src="userinfo.avatar" alt="" /></div>
            <div class="userId">UID:{{ userinfo.userId }}</div>
          </div>
          <div class="right">
            <img src="~@/assets/img/exit.png" alt="" @click="loginout" />
          </div>
        </div>
      </div>
      <div
        style="
          background-color: rgba(255, 255, 255, 0.8);
          width: 100%;
          height: calc(100vh - 1.13rem);
        "
      >
        <van-cell-group :border="false">
          <van-cell
            v-if="userinfo == null ? true : false"
            to="/login"
            :border="false"
            title-style="color:rgba(237, 147, 30, 1)"
            title="login"
            is-link
          />
          <van-cell
            :class="{ active: isActive('/index') }"
            :border="false"
            :icon="isActive('/index') ? 'location' : ''"
            to="/index"
            :title="lang.v.title_2"
            is-link
          />
          <van-cell
            v-if="userinfo == null ? false : true"
            :class="{ active: isActive('/invite') }"
            :icon="isActive('/invite') ? 'location' : ''"
            :border="false"
            to="/invite"
            :title="lang.v.Invite"
            is-link
          />
          <van-cell
            :class="{ active: isActive('/wallet') }"
            :icon="isActive('/wallet') ? 'location' : ''"
            :border="false"
            to="/wallet"
            :title="lang.v.title_3"
            is-link
          />
          <van-cell
            :class="{
              active:
                isActive('/intelligent') || isActive('/become_intelligent'),
            }"
            :icon="
              isActive('/intelligent') || isActive('/become_intelligent')
                ? 'location'
                : ''
            "
            :border="false"
            @click="intelligent"
            :title="lang.v.title_4"
            is-link
          />
          <van-cell
            :class="{ active: isActive('/copyright') }"
            :icon="isActive('/copyright') ? 'location' : ''"
            :border="false"
            to="/copyright"
            :title="lang.v.title_5"
            is-link
          />
          <van-cell
            :class="{ active: isActive('/community') }"
            :icon="isActive('/community') ? 'location' : ''"
            :border="false"
            to="/community"
            :title="lang.v.title_6"
            is-link
          />
          <van-cell
            :class="{ active: isActive('/aboutus') }"
            :icon="isActive('/aboutus') ? 'location' : ''"
            :border="false"
            to="/aboutus"
            :title="lang.v.title_7"
            is-link
          />
          <van-cell
            :class="{ active: isActive('/changeAccount') }"
            :icon="isActive('/changeAccount') ? 'location' : ''"
            :border="false"
            to="/changeAccount"
            :title="lang.v.title_9"
            is-link
          />
          <van-cell
            :class="{ active: isActive('/redemptionCode') }"
            :icon="isActive('/redemptionCode') ? 'location' : ''"
            :border="false"
            to="/redemptionCode"
            :title="lang.v.title_11"
            is-link
          />
          <van-cell
            :class="{ active: isActive('/im') }"
            :icon="isActive('/im') ? 'location' : ''"
            :border="false"
            to="/im"
            :title="lang.v.title_13"
            is-link
          />
          <van-cell
            :class="{ active: isActive('/transactionServeList') }"
            :icon="isActive('/transactionServeList') ? 'location' : ''"
            :border="false"
            to="/transactionServeList"
            :title="lang.v.title_12"
            is-link
          />
          <van-cell
            :border="false"
            v-if="userinfo == null ? false : true"
            @click="loginout"
            :title="lang.v.title_10"
            is-link
          />
        </van-cell-group>
      </div>
    </van-popup>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, defineExpose } from 'vue';
import router from '@/router';
import { lang } from '@/lang';
import { logout } from '@/api/fetcher';
import { useRoute } from 'vue-router';
import { clearLocalUser } from '@/utils/memory';
import dataCenter from '@/dataCenter';

const more = () => {
  show.value = false;
};
const route = useRoute();
const show = ref(false);
const index = () => {
  router.push('/index');
};
const gologin = () => {
  router.push('/login');
};
const intelligent = () => {
  if (!dataCenter.user.data) {
    router.push('/intelligent');
  } else {
    if (userinfo.value.lastTalentCardType == null) {
      router.push('/intelligent');
    } else {
      sessionStorage.setItem(
        'intelligent_id',
        JSON.stringify(userinfo.value.lastTalentCardType)
      );
      router.push('/become_intelligent');
    }
  }
};
const loginout = () => {
  logout().then(() => {
    clearLocalUser();
    router.push('/login');
  });
};
const isActive = computed(() => {
  return address => route.path === address;
});
const userinfo = ref({});

const refresh = () => {
  userinfo.value = dataCenter.user.data;
};
onMounted(async () => {
  try {
    const userInfoString = dataCenter.user.data;
    if (userInfoString && userInfoString !== 'undefined') {
      userinfo.value = userInfoString;
    }
  } catch (e) {
    console.error('Error parsing userInfo from localStorage:', e);
  }
});

defineExpose({ refresh });
</script>
<style lang="less" scoped>
.top {
  width: 100%;
  display: flex;
  padding-top: 0.22rem;
  padding-bottom: 0.1rem;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  .left {
    width: 0.97rem;
    height: 0.26rem;
    background: url(~@/assets/img/logo_top.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 0.02rem;
    margin-left: 0.14rem;
  }

  .right {
    display: flex;

    .picture {
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      background-color: #eea849;
      margin-right: 0.12rem;
      font-size: 0.12rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.3rem;
      text-align: center;
      display: flex;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .more {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.14rem;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.top_block {
  height: 0.68rem;
  width: 100%;
}
.top_menu {
  width: 100%;
  display: flex;
  padding-top: 0.22rem;
  padding-bottom: 0.1rem;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);

  .left {
    display: flex;
    margin-left: calc(3.75% + 0.12rem);

    .logo {
      width: 0.84rem;
      height: 0.26rem;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .right {
    .more {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: calc(3.75% + 0.12rem);
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.nav {
  width: 92.5%;
  display: flex;
  height: 0.5rem;
  margin: auto;
  justify-content: space-between;

  .left {
    display: flex;

    .picture {
      display: flex;
      width: 0.32rem;
      height: 0.32rem;
      margin-left: 0.12rem;
      margin-top: 0.09rem;
      img {
        background-color: #eea849;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .userId {
      height: 0.5rempx;
      font-size: 0.16rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      line-height: 0.5rem;
      margin-left: 0.12rem;
      background: linear-gradient(90deg, #f46b45 0%, #eea849 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .right {
    width: 0.24rem;
    height: 0.24rem;
    margin-right: 0.02rem;
    margin-top: 0.13rem;
    display: flex;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

:deep(.van-cell-group) {
  width: 92.5%;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  border: none !important;

  .van-cell {
    background-color: rgba(255, 255, 255, 0.8);

    .van-badge__wrapper {
      color: #000;
    }
  }
}

.active {
  background-color: #ed931e !important;
  border-radius: 0.08rem;

  :deep(.van-badge__wrapper) {
    color: #ffffff !important;
  }

  :deep(.van-cell__title) {
    color: #ffffff;
  }

  :deep(.van-cell__right-icon) {
    display: none;
  }
}
</style>
